<template>
	<div class="footer">
		<div style="background-color:#383d61" class="footer--bg"></div>
		<div class="footer--inner">
			<div class="am-g">
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">Product Center</strong>
						<ul class="footer_navigation">
							<li class="footer_navigation--item"><router-link to="/product" class="footer_navigation--link">moving head light</router-link></li>
							<li class="footer_navigation--item"><router-link to="/product" class="footer_navigation--link">par light</router-link></li>
							<li class="footer_navigation--item"><router-link to="/product" class="footer_navigation--link">led strobe light</router-link></li>
						</ul>
					</div>
				</div>
				<div class="am-u-md-6 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">About Us</strong>
						<div class="footer_about">
							<p class="footer_about--text">
								Guangzhou Huayang Stage Lighting, founded in 2010, specializes in stage lighting equipment like moving head lights and LED lights. Exporting to over 50 countries, we are known for high-quality products and excellent customer service.
							</p>
						</div>
					</div>
				</div>
<!--				<div class="am-u-md-3 ">-->
<!--					<div class="footer_main&#45;&#45;column">-->
<!--						<strong class="footer_main&#45;&#45;column_title">Contact details</strong>-->
<!--						<ul class="footer_contact_info">-->
<!--							<li class="footer_contact_info&#45;&#45;item"><i class="am-icon-phone"></i><span>Contact Hotline:15521122288</span></li>-->
<!--							<li class="footer_contact_info&#45;&#45;item"><i class="am-icon-map-marker"></i><span>Tianhe District, Guangzhou City, Guangdong Province, China</span></li>-->
<!--							<li class="footer_contact_info&#45;&#45;item"><i class="am-icon-clock-o"></i><span>Monday to Friday,8:30 - 17:30<br>(UTC+8)</span></li>-->
<!--						</ul>-->
<!--					</div>-->
<!--				</div>-->
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">hystagelight.com</strong>
						<div class="footer_about">
<!--							<p class="footer_about&#45;&#45;text">-->
<!--								https://hystagelight.en.alibaba.com/-->
<!--							</p>-->
							<img src="hystagelight.com.png" style="height: 140px">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>

</style>
