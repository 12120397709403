<template>
  <div class="message-board">
    <h2>Contact Us</h2>
    <div class="message-form">
      <div class="form-group">
        <input type="text" v-model="messageForm.leaveMessageUserName" placeholder="Full Name" />
      </div>
      <div class="form-group">
        <input type="tel" v-model="messageForm.leaveMessageUserPhone" placeholder="Phone Number" />
      </div>
      <div class="form-group">
        <input type="email" v-model="messageForm.leaveMessageUserMail" placeholder="Email Address" />
      </div>
      <div class="form-group">
        <textarea v-model="messageForm.leaveMessageText" placeholder="Your Message" rows="4"></textarea>
      </div>

      <div class="form-group" style="display: flex;">
        <img :src="captchaImageUrl" alt="Verification Code" @click="refreshCaptcha" />
        <input type="text" class="verificationCode" v-model="messageForm.captcha" placeholder="Enter Verification Code" style="width: 170px" />
        <button class="submit-btn" @click="submitMessage" style="margin-left: auto;">Send Message</button>
      </div>
      <!-- 加载动画 -->
      <div v-if="isLoading" class="loading-overlay">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/common/Layout";

export default {
  name: "LeaveMessage",
  components: {Layout},
  data(){
    return{
      captchaImageUrl: '/portal_server/captcha?' + Date.now(), // 初始加载验证码
      messageForm: {
        leaveMessageUserName: '',
        leaveMessageUserPhone: '',
        leaveMessageUserMail: '',
        leaveMessageText: '',
        captcha: '', // 用户输入的验证码
      },
      isLoading: false, // 新增加载状态
    }
  },
  computed:{
  },
  methods:{
    refreshCaptcha() {
      this.captchaImageUrl = '/portal_server/captcha?' + Date.now(); // 点击时刷新验证码
    },
    submitMessage() {
      if (!this.messageForm.captcha) {
        alert('Please enter the verification code!');
        return;
      }
      // 简单的表单验证
      if (!this.messageForm.leaveMessageUserName.trim() ||
          !/^\d{10,15}$/.test(this.messageForm.leaveMessageUserPhone) ||
          !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.messageForm.leaveMessageUserMail) ||
          !this.messageForm.leaveMessageText.trim()) {
        alert('Please provide complete and valid information!');
        return;
      }
      this.isLoading = true; // 开始加载动画
      // 发送请求
      this.postRequest('/leaveMessage',this.messageForm)
          .then(resp => {
            this.isLoading = false; // 停止加载动画
            if (resp && resp.data === true) {
              alert('Your message has been submitted successfully!');
              // 清空表单
              this.messageForm = {
                leaveMessageUserName: '',
                leaveMessageUserPhone: '',
                leaveMessageUserMail: '',
                leaveMessageText: ''
              };
              window.location.href = 'https://www.hystagelight.com/#/about#contact-us-id';
            } else {
              alert('Invalid verification code, please try again!');
            }
            // 刷新验证码
            this.refreshCaptcha();
          })
          .catch(error => {
            // console.error('提交留言失败:', error);
            alert('Message submission failed. Please try again later.');
          })
          .finally(() => {
            this.isLoading = false; // 停止加载动画
          });;
    }
  }
}
</script>

<style scoped>
.message-board {
  width: 90%;
  margin: 0 auto 30px;
  text-align: left;
}

.message-form {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
}

.submit-btn {
  background-color: #59bcdb;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: #4aa3c0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top: 4px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*移动端*/
@media screen and (max-width: 640px) {

  .am-breadcrumb li {
    display: inline; /* 确保所有元素仍在一行 */
  }
  .message-board {
    width: 90%;
  }
  .form-group{
    display: block!important;
  }
  .submit-btn{
    margin-top: 15px;

  }
}
</style>