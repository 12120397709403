<template>
  <div class="header-wrapper">
    <div class="header">
      <div class="header-left">
        <img src="../../../assets/images/logo.png" alt="">
      </div>
      <div class="hamburger" @click="toggleMenu">
        <span :class="{ 'active': isMenuOpen }"></span>
        <span :class="{ 'active': isMenuOpen }"></span>
        <span :class="{ 'active': isMenuOpen }"></span>
      </div>
      <div class="header-mid">
        <div class="header-item">
          <i style="color:#7c6aa6" class="contact-icon am-icon-phone"></i>
          <div class="item">
            <strong>+8615521122288</strong>
            <span>8:30 - 17:30(UTC+8)</span>
          </div>
        </div>
        <div class="header-item">
          <i style="color:#7c6aa6" class="contact-icon am-icon-map-marker"></i>
          <div class="item">
            <!--						<strong>Room 701, No. 4 Lingshan East Road, Tianhe District, Guangzhou</strong>-->
            <strong>Shimen Street, Baiyun District, Guangzhou</strong>
            <span>Guangzhou Huayang Stage Lighting Equipment Co., Ltd.</span>
          </div>
        </div>
      </div>
      <div class="header-right">
        <a class="" @click="toAboutUs">
          <button type="button">Contact Us</button>
        </a>
      </div>
    </div>

    <div class="nav-wrapper header-default">
      <div class="nav">
        <ul class="am-nav am-nav-pills am-nav-justify">
          <li>
            <router-link class="router" to="/index">Home</router-link>
          </li>
          <li>
            <router-link class="router" to="/product">Products</router-link>
          </li>
          <li>
            <router-link class="router" to="/example">Examples</router-link>
          </li>
          <li>
            <router-link class="router" to="/about">About us</router-link>
          </li>
        </ul>
      </div>
    </div>

    <!--移动端导航栏-->
    <div class="mobile-nav" :class="{ 'active': isMenuOpen }">
      <nav>
        <ul>
          <li>
            <router-link class="router" to="/index">Home</router-link>
          </li>
          <li>
            <router-link class="router" to="/product">Products</router-link>
          </li>
          <li>
            <router-link class="router" to="/example">Examples</router-link>
          </li>
          <li>
            <router-link class="router" to="/about">About us</router-link>
          </li>
          <li><a class="router" href="javascript:void(0);" @click="toAboutUs">Contact Us</a></li>
        </ul>
      </nav>
      <!-- 添加联系方式 -->
      <div class="contact-info">
        <div class="contact-item">
          <i class="contact-icon am-icon-phone"></i>
          <div class="item">
            <strong>+8615521122288</strong>
            <span>8:30 - 17:30(UTC+8)</span>
          </div>
        </div>
        <div class="contact-item">
          <i class="contact-icon am-icon-map-marker"></i>
          <div class="item">
            <strong>Shimen Street, Baiyun District, Guangzhou</strong>
            <span>Guangzhou Huayang Stage Lighting Equipment Co., Ltd.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppFunctions from "@/utils/AppFunctions";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      AppFunctions,
      isMenuOpen: false,
      navItems: [
        {name: 'Home', path: '/index'},
        {name: 'Products', path: '/product'},
        {name: 'Example', path: '/example'},
        {name: 'About us', path: '/about'},
        {name: 'Contact us', path: '/contact'}
      ]
    }
  },
  methods: {
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        AppFunctions.addClass('.header-default', 'sticky');
      } else if (scrolled <= 100) {
        AppFunctions.removeClass('.header-default', 'sticky');
      }
    },
    toAboutUs() {
      this.$router.push({path: '/about', hash: 'contact-us-id'})
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : '';
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = '';
    }
  },
  watch: {
    // 监听路由变化
    '$route'() {
      this.closeMenu();
    }
  },
  created() {
    window.addEventListener('scroll', this.toggleStickyHeader);
  },
  mounted() {
    this.toggleStickyHeader();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.toggleStickyHeader);
    document.body.style.overflow = '';
  }

}
</script>

<style scoped>
.router.router-link-active {
  /*color: #fff;*/
  /*background-color: #0e90d2;*/
  /*border: 1px solid #0e90d2;*/
  cursor: default;

  color: #0e90d2;
}

/*================================ Header ================================*/
.header-wrapper {
  display: flex;
  flex-direction: column;
}

.header-wrapper .header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  /*background-color: yellow;*/
}

.header-wrapper .header .header-left {
  height: 60px;
}

.header-wrapper .header .header-left img {
  height: 100%;
}

.header-wrapper .header .header-mid {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 0px 50px;
}

.header-wrapper .header .header-mid .header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  font-size: 15px;
}

.header-wrapper .header .header-mid .header-item i {
  font-size: 30px;
}

.header-wrapper .header .header-mid .header-item .item {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}

.header-wrapper .header .header-right {
  padding: 10px 0;
}

.header-wrapper .header .header-right button {
  padding: 8px 16px;
  border: 1px solid #59bcdb;
  border-radius: 4px;
  color: #59bcdb;
  background-color: #fff;
}

.header-wrapper .header .header-right button:hover {
  background-color: #59bcdb;
  color: #ffffff;
}

/* header固定 */
.sticky {
  background-color: #ffffff;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  animation: headerSticky .95s ease forwards;
}

.hamburger {
  display: none;
  width: 30px;
  height: 25px;
  position: relative;
  cursor: pointer;
  z-index: 1000;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #333;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 10px;
}

.hamburger span:nth-child(3) {
  top: 20px;
}

/* 汉堡菜单动画 */
.hamburger span.active:nth-child(1) {
  transform: rotate(45deg);
  top: 10px;
}

.hamburger span.active:nth-child(2) {
  opacity: 0;
}

.hamburger span.active:nth-child(3) {
  transform: rotate(-45deg);
  top: 10px;
}

/* 移动端导航菜单 */
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  z-index: 999;
  padding-top: 80px;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.mobile-nav.active {
  transform: translateX(0);
}

.mobile-nav nav {
  width: 100%;
}

.mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav li {
  text-align: center;
  margin: 20px 0;
}

.mobile-nav a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 10px;
  transition: color 0.3s ease;
}

.mobile-nav a:hover {
  color: #7c6aa6;
}

.contact-info {
  padding: 20px;
  margin-top: 20px;
  border-top: 1px solid #eee;
}

.contact-info .contact-item {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.contact-info .contact-icon {
  font-size: 24px;
  color: #7c6aa6;
  margin-right: 15px;
}

.contact-info .item {
  display: flex;
  flex-direction: column;
}

.contact-info .item strong {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.contact-info .item span {
  font-size: 14px;
  color: #666;
}

/* 媒体查询 */
@media screen and (max-width: 768px) {
  .header-wrapper .header {
    justify-content: space-between;
    padding: 15px 20px;
    position: relative;
    z-index: 1000;
    background: #fff;
  }

  .header-wrapper .header .header-left {
    height: 40px;
  }

  .header-wrapper .header .header-mid,
  .header-wrapper .header .header-right {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .nav-wrapper {
    display: none;
  }
}

/* PC端样式保持不变 */
@media screen and (min-width: 769px) {
  .mobile-nav {
    display: none;
  }
}
</style>
