<template>
  <div v-if="showButton" class="scroll-to-top" @click="scrollToTop">
    ↑
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButton: false, // 是否显示滑到顶部按钮
    };
  },
  methods: {
    scrollToTop() {
      // 滑动到顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    handleScroll() {
      // 当滚动距离大于一定高度时，显示按钮
      this.showButton = window.scrollY > 300;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #59bcdb;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
  font-size: 30px; /* 调整字体大小，增大箭头 */
  font-weight: bold; /* 设置箭头加粗 */
}
.scroll-to-top:hover {
  background-color: #59bcdb;
}
</style>
