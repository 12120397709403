<template>
	<!-- :key="$route.fullPath" 解决了路由前缀相同时跳转不刷新 -->
<!--  <router-view :key="$route.fullPath"/>-->
  <div id="app">
    <keep-alive :include="['ProductView']">
      <router-view :key="cacheKey" />
    </keep-alive>
    <ScrollToTop /> <!-- 滑到顶部按钮 -->
  </div>

</template>

<style>
        /* PC端默认自适应 */
    body, html {
        width: 100%;
        max-width: 100%; /* 保证 PC 端自适应 */
        margin: 0;
        padding: 0;
        font-family: "Times New Roman",sans-serif;
    }

    /* 针对手机屏幕：使用固定大小 */
/*    @media only screen and (max-width: 768px) {
        body, html {
            width: 1200px;  !* 固定宽度，比如 1200px *!
            margin: 0 auto; !* 使页面内容居中 *!
            overflow-x: scroll; !* 横向滚动 *!
        }
    }*/
</style>

<script>
import ScrollToTop from "./components/common/ScrollToTop.vue";
export default {
  name: 'App',
  // 这里可以动态决定缓存哪些页面
  computed: {
    // 如果需要动态生成 key
    cacheKey() {
      return this.$route.name === 'ProductView' ? this.$route.fullPath : this.$route.name;
    },
  },
  components: {
    ScrollToTop,
  },
};
</script>